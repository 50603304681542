import download from './download'
import accountInfo from './accountInfo'
import rank from './rank'
import b5News from './b5News'
import login from './login'
import dan from './dan'
import index from './index'

export default {
  // 首页
  index,
  download,
  accountInfo,
  rank,
  b5News,
  login,
  dan,
}
