export default {
  nav: {
    overview: '概览',
    data: '数据',
    record: '比赛记录',
    collection: '收藏品',
    account: '账号中心',
  },
  index: {
    player: 'CSGO选手',
    banned: '已被封禁',
    black: '已被关小黑屋',
    time: '定罪于',
    freeTime: '将解封于',
  },
  overview: {
    title: {
      career: '生涯',
      creditScore: '信用分',
      season: '本赛季',
      clan: '战队',
      preference: '偏爱',
      peripherals: '外设',
      pc: 'PC',
      recentFive: '近5场比赛',
      edit: '编辑',
      save: '保存',
      desc: '的战绩',
      copy: '已复制账号链接',
    },
    career: {
      matchPlayed: '比赛',
      matchWin: '胜利',
      matchTime: '比赛时长',
      medalsNum: '荣誉勋章',
      enemyNums: '击杀',
      headShotNums: '爆头',
      killMore: '多杀',
      clutchWin: '残局胜利',
    },
    season: {
      segment: '段位',
      elo: 'ELO',
      race: '比赛',
      rate: '胜率',
      firstkill: '首杀',
      endrate: '残局胜率',
      hs: 'HS%',
      kd: 'K/D',
      adr: 'ADR',
      kpr: 'KPR',
      rws: 'RWS',
      b5rating: 'B5 Rating',
    },
    preference: {
      race: '比赛',
      win: '获胜',
      winrate: '胜率',
      kill: '击杀',
      headshot: '爆头',
      headshotrate: '爆头率',
    },
    peripherals: {
      monitor: '显示器',
      mouse: '鼠标',
      keyboard: '键盘',
      headset: '耳机',
      mousepad: '鼠标垫',
      soundcard: '声卡',
      wireclamp: '线夹',
      notready: '未设置',
      tips: '请搜索型号',
    },
    pc: {
      system: 'System',
      cpu: 'CPU',
    },
    recentFive: {
      kd: 'K/D',
      b5rating: 'B5 Rating',
      adr: 'ADR',
    },
  },
  data: {
    notPro: '完成10场定级赛解锁生涯战绩',
    noData: '暂无数据',
    title: {
      level: '水平组',
      trend: '近10场天梯趋势',
      style: '风格',
      data: '数据',
      winrate: '胜率',
      weapon: '常用武器',
      map: '地图池',
      history: '历史赛季',
    },
    level: {
      rank: '排名',
      elo: 'ELO',
      tad: '待定',
    },
    trend: {
      headshot: '爆头率',
    },
    style: {
      firstkill: '首杀',
      survive: '爆头率',
      awp: 'AWP',
      endgame: '残局',
      blind: '致盲',
      props: '道具伤害',
    },
    data: {
      race: '比赛',
      kill: '击杀',
      assist: '助攻',
      dead: '死亡',
      b5rating: 'B5 Rating',
      rws: 'RWS',
      adr: 'ADR',
      kd: 'K/D',
      doublekill: '2杀',
      triplekill: '3杀',
      ultrakill: '4杀',
      rampage: '5杀',
    },
    winrate: {
      race: '比赛',
      round: '回合',
      pistol: '手枪局',
      endgame: '残局',
      win: '胜',
      lose: '负',
      tie: '平',
      ct: 'CT',
      t: 'T',
    },
    weapon: {
      front: '正面',
      back: '背身',
      sideways: '侧身',
      head: '头部',
      chest: '胸部',
      arm: '手臂',
      abdomen: '腹部',
      leg: '腿部',
    },
    map: {
      total: '总',
      ct: 'CT',
      t: 'T',
      mapname: '地图',
      session: '场次',
      average: '场均击杀',
      winrate: '胜率',
      pistolwin: '手枪局胜率',
      endgamewin: '残局胜率',
      headshot: '爆头率',
      adr: 'ADR',
      rws: 'RWS',
      b5rating: 'B5 RATING',
    },
    history: {
      season: '赛季',
      rank: '排名',
      segment: '段位',
      elo: 'ELO',
      race: '比赛',
      win: '胜场',
      winrate: '胜率',
      summary: '赛季总结',
      previous: '上一页',
      next: '下一页',
      seasonOverview: '赛季概览',
      seasonStatistics: '数据统计',
      seasonMapData: '地图分析',
      seasonWeaponsData: '常用武器',
      moreKill: '击杀',
      pistol: '手枪局',
      firstkill: '首杀成功率',
      endgame: '残局',
      matchWin: '胜',
      losePlayed: '负',
      drawPlayed: '平',
      weaponname: '武器',
      kills: '总击杀',
      killavg: '场均击杀',
      headshotnums: '爆头',
      headshotrate: '爆头率',
      oneKill: '1杀',
      twoKill: '2杀',
      threeKill: '3杀',
      fourKill: '4杀',
      fiveKill: '5杀',
      b5rating: 'B5 Rating',
      rws: 'RWS',
      adr: 'ADR',
      kpr: 'KPR',
      hs: 'HS%',
    },
  },
  record: {
    select: '赛季选择',
    type: '比赛类型',
    time: '比赛时间',
    map: '地图',
    achievement: '成就',
    score: '比分',
    result: '胜负',
    scoreboard: '计分板',
    notDownloadTip: '无法下载Demo',
  },
  collection: {
    noData: '暂无数据',
    all: '全部',
    reward: '奖杯',
    medal: '勋章',
    souvenir: '纪念品',
    background: '背景板',
    matchcard: '匹配卡片',
    watch: '查看',
    show: '展示',
    hide: '下架',
    desc: '说明',
    receiveTime: '发放日期',
  },
}
