import psEvent from './psEvent'

export function toQT(action, params) {
  let obj
  if (typeof params === 'string' || typeof params === 'number') {
    obj = {
      action,
      params,
    }
  } else if (Array.isArray(params)) {
    obj = {
      action,
      data: params,
    }
  } else if (typeof params === 'object') {
    obj = {
      action,
      ...params,
    }
  } else if (typeof params === 'undefined') {
    obj = {
      action,
    }
  }
  console.log(obj)
  window.bridge.fromWebPageToLocal(JSON.stringify(obj))
}
export function fromQT(res) {
  res = JSON.parse(res)
  psEvent.trigger(res.action, res)
}
