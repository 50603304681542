//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { toQT } from '@/qt/tools'
import indexApi from '@/api/index'
import { mapState, mapMutations } from 'vuex'
import B5Login from '@/components/login/Login'
import { message, throttle } from '@/utils/util'

export default {
  name: 'App',
  components: {
    B5Login,
  },
  data() {
    return {
      location: '',
      showLoginMask: false,
      showNav: true,
      baseImgUrl: 'https://img.b5csgo.com.cn/',
      permission: false,
      //客户端网页不允许退出登录（退出登录会导致内嵌页面打不开（共用一个cookie））
      platform: 'web',
    }
  },
  computed: {
    ...mapState({
      globalPlatform: (state) => state.app.platform,
      globalLogin: (state) => state.app.hasLogin,
      baseInfo: (state) => state.app.baseInfo,
    }),
  },
  // middleware: 'checkIn',
  mounted() {
    this.location = window.location
    const { target, steamId, token, platform } = this.$route.query
    this.platform = platform
    if (platform === 'client' || this.globalPlatform === 'client') {
      this.$store.dispatch('app/setPlatform', 'client')
      try {
        initQT()
      } catch (error) {
        console.log({ initError: error }) // eslint-disable-line
      }
    } else {
      this.$store.dispatch('app/initializeGlobalPlatform')
    }
    if (steamId && token && platform !== 'client') {
      indexApi
        .autoInit(steamId, token)
        .then((res) => {
          if (res.code === 0) {
            // 成功后调用verify解析jwt并关闭登录弹窗
            this.checkIn(target)
          } else {
            toQT('loginFailed')
            console.log({ msg: res.msg }) // eslint-disable-line
          }
        })
        .catch((err) => console.log(err)) // eslint-disable-line
    } else {
      try {
        this.checkIn()
      } catch (error) {
        console.log({ error }) // eslint-disable-line
      }
    }
    this.INITIALIZE_BROWSERCHECK()
    this.scrollBroadcast()
  },
  methods: {
    ...mapMutations({
      INITIALIZE_BROWSERCHECK: 'app/INITIALIZE_BROWSERCHECK',
    }),
    // 显示登录弹窗
    handleClickLogin() {
      this.showLoginMask = true
    },
    // 子组件触发关闭事件
    resetShowLogin() {
      this.showLoginMask = false;
      location.reload()
    },
    // 初始化信息
    initializeData() {
      this.$store.dispatch('app/initializeLogin')
      this.$store.dispatch('accountInfo/initializeAccountInfo')
      this.$store.dispatch('competition/initializeCompetition')
    },
    //管理我的赛事
    handleMytournament() {
      this.$router.push('/myTournaments')
    },
    // 退出登录清除数据76y
    logout() {
      indexApi
        .webLogOut()
        .then((res) => {
          if (res.code === 0) {
            if (this.globalPlatform === 'client') {
              toQT('hasLogout')
            }
            this.initializeData()
            sessionStorage.setItem('b5web_login', false)
            document.title =
              'B5对战平台 - 高配服务器、严格反作弊、享受纯净电竞环境'
            // location.href = 'https://www.b5csgo.plus/'
            this.$router.push('/')
          }
        })
        .catch(() => {
          this.$message({
            message: '服务器开小差了，请刷新重试',
            type: 'error',
            customClass: 'logout-fail-message',
            center: true,
            duration: 1000,
            offset: 340,
          })
        })
    },
    // 点击跳转个人战绩中心
    toUserInfo() {
      this.$router.push(`/accountInfo/overview?domain=${this.baseInfo.domain}`)
    },
    // 自动登录
    checkIn(targetURL) {
      indexApi
        .checkIn()
        .then((res) => {
          if (res.code === 0) {
            const obj = res.data
            this.$store.dispatch('app/setLogin', true)
            sessionStorage.setItem('b5web_login', true)
            this.$store.dispatch('app/setBaseInfo', obj)
          } else {
            this.initializeData()
          }
          if (targetURL) {
            this.$router.push(`/${targetURL}`).catch(() => { })
          }
        })
        .catch((error) => {
          console.log({ error }) // eslint-disable-line
          this.initializeData()
        })
    },
    // 点击跳转兔小巢
    handleCommon() {
      if (this.globalLogin) {
        // 已登录跳转
        window.open(
          `https://support.qq.com/product/193540?openid=${this.baseInfo.domain}&avatar=https://img.b5csgo.com.cn/${this.baseInfo.headImg}&nickname=${this.baseInfo.nickName}`,
          'only-one'
        )
      } else {
        // 未登录弹登录框
        sessionStorage.setItem('openSupport', true)
        this.showLoginMask = true
      }
    },
    handleContact() {
      if (this.globalLogin) {
        // 已登录跳转
        window.open(
          `https://support.qq.com/product/193540/faqs/82747?openid=${this.baseInfo.domain}&avatar=https://img.b5csgo.com.cn/${this.baseInfo.headImg}&nickname=${this.baseInfo.nickName}`,
          'only-one'
        )
      } else {
        // 未登录弹登录框
        sessionStorage.setItem('openContact', true)
        this.showLoginMask = true
      }
    },
    handleAimbot() {
      if (this.globalLogin) {
        // 已登录跳转
        this.$router.push('/aimbot')
      } else {
        // 未登录弹登录框
        sessionStorage.setItem('openContact', true)
        this.showLoginMask = true
      }
    },
    handleGametool() {
      if (this.globalLogin) {
        if (this.baseInfo.role === 2 || this.baseInfo.role === 3) {
          this.$router.push('/gametool')
        } else {
          message(this, '当前暂未开放')
        }
      } else {
        // 未登录弹登录框
        sessionStorage.setItem('openContact', true)
        this.showLoginMask = true
      }
    },
    // 数据持久化
    saveData(event) {
      // Cancel the event as stated by the standard.
      // event.preventDefault();
      // Chrome requires returnValue to be set.
      // event.returnValue = "12";
      sessionStorage.setItem('b5web_store', JSON.stringify(this.$store.state))
      // return "";
    },
    handleEmitByVerify(obj) {
      this.showNav = obj.flag
    },
    /**
     * 注册全局scroll广播
     * 无需处理事件移除
     */
    scrollBroadcast() {
      // 默认不处理防抖节流
      // window.addEventListener('scroll', throttle((e)=>{
      //   this.$root.$emit('scroll', e )
      // },200))
      window.addEventListener('scroll', (e) => {
        this.$root.$emit('scroll', e)
      })
    },
    // 判断是否当前路由
    isCurrentRoute(target) {
      let path = this.$route.path
      return path.startsWith(`/${target}`)
    },

  }
}
