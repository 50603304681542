export default {
  title: '天梯匹配',
  tips: '每日6:00AM更新',
  refresh: '榜单刷新时间',
  thead: {
    rank: '排名',
    id: 'ID',
    played: '场次',
    firstkill: '场均首杀',
    adr: 'ADR',
    b5rating: 'B5 Rating',
    duan: '段位',
    elo: '天梯分',
  },
  more: '查看更多',
  over: '已到底',
}
