export const state = () => ({
  status: 0,
  compType: 1

})

export const mutations = {
  setStatus: (state, num) => {
    state.status = num
  },
  setCompType: (state, num) => {
    state.compType = num
  },
}

export const actions = {

}
