import QWebChannel from '~/qt/qwebchannel0.js'
import { fromQT } from '../qt/tools.js'
// eslint-disable-next-line new-cap
window.initQT = function () {
  new QWebChannel(qt.webChannelTransport, function (channel) {
    // eslint-disable-line
    // channelObject 是步骤二中注册qwebchannle对象中的标识符-->
    window.bridge = channel.objects.channelObject

    // fromLocalToWebPage是cpp端声明的信号 response是信号带过来的参数 ，跟qt信号一样理解-->

    window.bridge.fromLocalToWebPage.connect(fromQT)
  })
}
