// 段位
export default {
  title: '当前技术水平组',
  Placement: '10场定级赛',
  Unrank: '未定级',
  Blackiron: '玄铁',
  Bronze: '青铜',
  Silver: '白银',
  Gold: '黄金',
  Platinum: '铂金',
  Masonry: '钻石',
  Master: '大师',
  Elite: '精英',
  GrandMaster: '无上大师',
}
