//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SocialSignin',
  data() {
    return {}
  },
  methods: {
    wechatHandleClick(thirdpart) {
      this.$emit('show-wechat')
    },
  },
}
