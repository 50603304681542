export const state = () => ({
  scheduleList: [],
  serverList:[]

})

export const mutations = {
  setScheduleList: (state, payload) => {
    state.scheduleList = payload
  },
  setServerList: (state, payload) => {
    state.serverList = payload
  },
}

export const actions = {

}
