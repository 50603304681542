// 处理async/await错误
export function awaitWrap(promise) {
	return promise.then((data) => [null, data]).catch((err) => [err, null]);
}

//常用函数的封装，比如事件的节流(throttle)和防抖(debounce)
export function throttle(fn, t) {
	//last为上一次触发回调的时间，timer是定时器
	let last;
	let timer;
	let interval = t || 500;
	return function () {
		let args = arguments;
		let now = +new Date();
		if (last && now - last < interval) {
			clearTimeout(timer);
			timer = setTimeout(() => {
				last = now;
				fn.apply(this, args);
			}, interval);
		} else {
			last = now;
			fn.apply(this, args);
		}
	};
}

export function debounce(fn, t) {
	let timer = null
	let interval = t || 500
	return function (...args) {
		if (timer) {
			clearTimeout(timer)
		}
		timer = setTimeout(() => {
			fn.call(this, ...args)
		}, interval)
	}
}

// 判断元素是否在可视区域
export function isInViewPort(element, distance = 30) {
	if (element) {
		const viewWidth = window.innerWidth || document.documentElement.clientWidth;
		const viewHeight = window.innerHeight || document.documentElement.clientHeight;
		const { top, right, bottom, left } = element.getBoundingClientRect();

		return top >= 0 && left >= 0 && right <= viewWidth && bottom <= viewHeight - +distance;
	} else {
		return false;
	}
}

export function message(vue, content, delay = 2000) {
	vue.$message({
		message: content,
		customClass: 'cumstom-el-message',
		duration: delay
	})
}

export function formDate(date) {
	var dat = new Date(date)
	var year = dat.getFullYear();
	var mon = dat.getMonth() + 1
	var day = dat.getDate()
	var newDate = year + "年 " + mon + "月" + day + "日";
	return newDate;
}
