/**
 * 发布订阅模式
 */
const publishSubscribeEvent = {
  /*
   * 缓存列表
   * clientList: {
   *    key: [
   *        id: <int>,        // 唯一标识
   *        fn: null          // 存放回调函数
   *    ]
   * }
   *
   */
  clientList: {},

  /*
   * 添加订阅者(订阅函数)，将订阅的类型与回调函数加入缓存列表
   * key: 消息的类型
   * id: 订阅的唯一标识
   * fn: 订阅的回调函数
   */
  listen(key, id, fn) {
    if (!this.clientList[key]) {
      this.clientList[key] = []
    }

    this.clientList[key].push({
      id,
      fn,
    })
    console.log('listen', this.clientList)
  },

  // 发布消息（发布函数）, 依次通知订阅者
  trigger() {
    const key = Array.prototype.shift.call(arguments)
    const fns = this.clientList[key]
    console.log('trigger', arguments)

    if (!fns || fns.length === 0) {
      return false
    }

    for (let i = 0; i < fns.length; i++) {
      fns[i].fn.apply(this, arguments)
    }
  },
}
export default publishSubscribeEvent
