//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseButton',
  props: {
    // 小尺寸、大尺寸、
    size: {
      type: String,
      validator(value) {
        // 这个值必须匹配下列字符串中的一个
        return ['small', 'big'].includes(value)
      },
    },
    // 是否为朴素按钮
    plain: {
      type: Boolean,
      default: false,
    },
    // 是否为block
    block: {
      type: Boolean,
      default: false,
    },
    // 是否禁用
    disable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * 点击事件，e为事件对象
     */
    clickEvent(e) {
      if (!this.disable) {
        this.$emit('click', e)
      }
    },
  },
}
