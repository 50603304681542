//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import md5 from 'js-md5'
import indexApi from '@/api/index'
import SocialSign from './SocialSignin'
// eslint-disable-next-line no-unused-vars
import Verify from './Verify'
// eslint-disable-next-line no-unused-vars
let self_ic;
export default {
  name: 'Login',
  components: {
    SocialSign,
    // 创建一个空组件，引入外部js文件
    Verify: {
      render(createElement) {
        const self = this
        return createElement('script', {
          attrs: {
            type: 'text/javascript',
            src: this.src,
          },
          on: {
            load() {
              self.$emit('loaded')
            },
          },
        })
      },
      props: {
        src: { type: String, required: true },
      },
    },
  },
  props: {
    showLoginMask: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      // 是否需要自动填充
      needAutoComplete: false,
      // 后台校验是否通过
      hasAccess: false,
      // 是否显示二维码
      showWechatQR: false,
      showErrorMsg: null,
      app: this,
      loginOptions: {
        emailOrUsername:'',
        encrypt:'',
        SessionId:'',
        Sig:'',
        Token:'',
        Scene:'',
        flag:1
      },
      options: {
        renderTo: '#b5-sc',
        width: 348,
        height: 48,
        default_txt: '点击按钮开始智能验证',
        success_txt: '验证成功',
        fail_txt: '验证失败，请在此点击按钮刷新',
        scaning_txt: '智能检测中',
        success: (data) => {
          this.loginOptions.SessionId = data.sessionId;
          this.loginOptions.Sig = data.sig;
          this.loginOptions.Token = NVC_Opt.token;
          this.loginOptions.Scene = NVC_Opt.scene;
          this.showErrorMsg = null;
          this.hasAccess = true;
        },
        fail() {
          console.log('ic error') // eslint-disable-line
        },
      },
    }
  },
  computed: {
    canClick() {
      return this.loginOptions.emailOrUsername && this.password && this.hasAccess
    },
  },
  mounted() {
    window.NVC_Opt = {
      appkey: 'FFFF0N0000000000979C',
      scene: 'ic_login',
      // 测试场景
      // scene: "nvc_register",
      token: ['FFFF0N0000000000979C', new Date().getTime(), Math.random()].join(
        ':'
      ),
      // 业务键字段，可为空。该参数可用于上线前测试，请按照代码集成后测试部分中的方法配置该字段值。
      // trans: { nvcCode: 400, key1: "code0" },
      // 当唤醒刮刮卡验证作为二次验证时，配置需要刮出的两个elements的图片资源。
      elements: [
        '//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png',
        '//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png',
      ],
      // 当唤醒刮刮卡验证作为二次验证时，配置刮动时的背景图像（自动应用平铺填充的方式）。
      bg_back_prepared:
        '//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png',
      // 当唤醒刮刮卡验证作为二次验证时，配置刮动时的前景图像（仅支持base64数据流）。
      bg_front:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC',
      // 当唤醒刮刮卡验证作为二次验证时，配置验证通过时显示的图标资源。
      obj_ok: '//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png',
      // 当唤醒刮刮卡验证作为二次验证时，配置验证通过时显示的背景图像（自动应用平铺填充的方式）。
      bg_back_pass:
        '//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png',
      // 当唤醒刮刮卡验证作为二次验证时，配置验证失败或异常时显示的图标资源。
      obj_error: '//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png',
      // 当唤醒刮刮卡验证作为二次验证时，配置验证失败或异常时显示的背景图像（自动应用平铺填充的方式）。
      bg_back_fail:
        '//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png',
      // 当唤醒刮刮卡验证作为二次验证时，用于自定义文案
      upLang: {
        cn: {
          _ggk_guide: '请摁住鼠标左键，刮出两面盾牌',
          _ggk_success: '恭喜您成功刮出盾牌<br/>继续下一步操作吧',
          _ggk_loading: '加载中',
          _ggk_fail: [
            '呀，盾牌不见了<br/>请',
            'javascript:noCaptcha.reset()',
            '再来一次',
            '或',
            'https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN',
            '反馈问题',
          ],
          _ggk_action_timeout: [
            '我等得太久啦<br/>请',
            'javascript:noCaptcha.reset()',
            '再来一次',
            '或',
            'https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN',
            '反馈问题',
          ],
          _ggk_net_err: [
            '网络实在不给力<br/>请',
            'javascript:noCaptcha.reset()',
            '再来一次',
            '或',
            'https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN',
            '反馈问题',
          ],
          _ggk_too_fast: [
            '您刮得太快啦<br/>请',
            'javascript:noCaptcha.reset()',
            '再来一次',
            '或',
            'https://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN',
            '反馈问题',
          ],
        },
      },
    }
  },
  methods: {
    // 记住密码 动态设置cookie过期时间
    rememberPassword() {
      this.needAutoComplete = !this.needAutoComplete
    },
    // 调用登录接口 成功后把结果写入vuex
    handleLogin() {
      // this.loginOptions.flag = this.needAutoComplete ? 1 : 0
      
      this.loginOptions.flag = 1
      this.loginOptions.encrypt = md5(this.password.trim()).toLowerCase()
      console.log(this.loginOptions)
      indexApi
        .userLogin(this.loginOptions)
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            // 成功后调用verify解析jwt并关闭登录弹窗
            indexApi.checkIn().then((res) => {
              if (res.code === 0) {
                const obj = res.data
                this.$emit('close-login')
                this.$store.dispatch('app/setLogin', true)
                sessionStorage.setItem('b5web_login', true)
                this.$store.dispatch('app/setBaseInfo', obj)
                if (sessionStorage.getItem('openSupport')) {
                  // 已登录跳转
                  window.open(
                    `https://support.qq.com/product/193540?openid=${obj.domain}&avatar=https://img.b5csgo.com.cn/${obj.headImg}&nickname=${obj.nickName}`,
                    'only-one'
                  )
                  sessionStorage.removeItem('openSupport')
                }
                if (sessionStorage.getItem('openContact')) {
                  // 已登录跳转
                  window.open(
                    `https://support.qq.com/product/193540/faqs/82747?openid=${obj.domain}&avatar=https://img.b5csgo.com.cn/${obj.headImg}&nickname=${obj.nickName}`,
                    'only-one'
                  )
                  sessionStorage.removeItem('openContact')
                }
              }
            })
          } else {
            this.showErrorMsg = res.msg
            document.getElementById('b5-sc').innerHTML = ''
            this.initCaptcha()
          }
        })
        .catch((err) => console.log(err)) // eslint-disable-line
    },
    // 初始化数据
    reSetData() {
      this.loginOptions.emailOrUsername = ''
      this.password = ''
      this.needAutoComplete = false
      this.hasAccess = false
    },
    // Dialog 关闭的回调
    handleCloseDialog() {
      this.reSetData()
      this.$emit('close-login')
    },
    // 初始化智能验证
    initCaptcha() {
      // eslint-disable-next-line new-cap
      self_ic = new smartCaptcha(this.options) // eslint-disable-line
      self_ic.init()
    },
    // 显示二维码
    showWechat() {
      this.showWechatQR = true
    },
  },
}
