import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cn from '@/lang/cn/cn'
import en from '@/lang/en/en'

Vue.use(VueI18n)

export default ({ app, store }) => {
  app.i18n = new VueI18n({
    // 语言标识
    locale: 'cn',
    fallbackLocale: store.state.lang || 'cn',
    messages: {
      cn, // 中文语言包
      en, // 英文语言包
    },
  })
  app.i18n.path = (link) => {
    if (app.i18n.locale === app.i18n.fallbackLocale) {
      return `/${link}`
    }
    return `/${app.i18n.locale}/${link}`
  }
}
