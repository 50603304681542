//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseSvg',
  props: {
    num: {
      type: Number,
      default: 1,
    },
    fatherComponent: {
      type: String,
      default: 'checkbox',
    },
  },
  data() {
    return { msg: '' }
  },
  methods: {
    /**
     * 点击事件，e为事件对象
     */
    clickEvent(e) {
      this.$emit('click', e)
    },
  },
}
