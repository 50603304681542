export const state = () => ({
  leftTime: -1,
  // 赛事信息
  eventData: {},
  // 赛事动态数据
  noticeData: [],
  // 奖金分配数据
  awardData: [],
  // 赛制数据
  systemData: [],
  // 赛事直播数据
  mediaData: [],
  // 友情赞助数据
  sponsorData: [],
  // 队员信息
  memberData: [],
})

export const mutations = {
  // 初始化赛事数据
  INITIALIZE_COMPETITION: (state) => {
    state.leftTime = -1
    state.eventData = {}
    state.noticeData = []
    state.awardData = []
    state.systemData = []
    state.mediaData = []
    state.sponsorData = []
    state.memberData = []
  },
  // 保存赛事信息
  REMBER_EVENTDATA: (state, obj) => {
    state.eventData = obj
  },
  // 赛事动态数据
  REMBER_NOTICEDATA: (state, arr) => {
    state.noticeData = arr
  },
  // 奖金分配数据
  REMBER_AWARDDATA: (state, arr) => {
    state.awardData = arr
  },
  // 赛制数据
  REMBER_SYSTEMDATA: (state, arr) => {
    state.systemData = arr
  },
  // 赛事直播数据
  REMBER_MEDIADATA: (state, arr) => {
    state.mediaData = arr
  },
  // 友情赞助数据
  REMBER_SPONSORDATA: (state, arr) => {
    state.sponsorData = arr
  },
  // 队员信息
  REMBER_MEMBERDATA: (state, arr) => {
    state.memberData = arr
  },
  // 倒计时
  REMBER_LEFTTIME: (state, num) => {
    state.leftTime = num
  },
}

export const actions = {
  // 初始化赛事数据
  initializeCompetition({ commit }) {
    commit('INITIALIZE_COMPETITION')
  },
  // 保存赛事信息
  setEventData({ commit }, obj) {
    commit('REMBER_EVENTDATA', obj)
  },
  // 赛事动态数据
  setNoticeData({ commit }, arr) {
    commit('REMBER_NOTICEDATA', arr)
  },
  // 奖金分配数据
  setAwardData({ commit }, arr) {
    commit('REMBER_AWARDDATA', arr)
  },
  // 赛制数据
  setSystemData({ commit }, arr) {
    commit('REMBER_SYSTEMDATA', arr)
  },
  // 赛事直播数据
  setMediaData({ commit }, arr) {
    commit('REMBER_MEDIADATA', arr)
  },
  // 友情赞助数据
  setSponsorData({ commit }, arr) {
    commit('REMBER_SPONSORDATA', arr)
  },
  // 队员信息
  setMemberData({ commit }, arr) {
    commit('REMBER_MEMBERDATA', arr)
  },
  // 倒计时
  setLeftTime({ commit }, num) {
    commit('REMBER_LEFTTIME', num)
  },
}
