import axios from 'axios'
import { Loading, Message } from 'element-ui'

let loadingInstance
const options = {
  lock: true,
  text: '正在请求数据...',
  spinner: 'el-icon-loading',
  background: 'rgba(255, 255, 255, 0.7)',
  customClass: 'global-loading',
}

function tip(msg = '网络或服务器连接失败') {
  Message.error(msg)
}

axios.defaults.baseURL = process.env.baseUrl

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    loadingInstance = Loading.service(options)
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    loadingInstance.close()
    if (response.data.code && response.data.code !== 0) {
      tip(response.data.msg)
    }
    return response
  },
  (error) => {
    // 对响应错误做点什么
    loadingInstance.close()
    tip('网络或服务器开了小差哦')
    return Promise.reject(error)
  }
)
