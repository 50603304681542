export const state = () => ({
  hasLogin: false,
  msg: '',
  // 加密后的steamid值
  sign: '',
  // 平台
  platform: 'web',
  // 语言
  lang: 'cn',
  // 登录后用户的基本信息
  baseInfo: {},
  // 默认通过浏览器版本
  passBrowserCheck: true
})

export const mutations = {
  // 初始化登录消息
  INITIALIZE_LOGIN: (state) => {
    state.hasLogin = false
    state.sign = ''
    state.platform = 'web'
    state.lang = 'cn'
    state.baseInfo = {}
  },
  // 全局保存是否登录
  REMBER_LOGIN: (state, bol) => {
    state.hasLogin = bol
  },
  // 全局保存sign
  REMBER_SIGN: (state, str) => {
    state.sign = str
  },
  // 全局保存登录后用户的基本信息
  REMBER_BASEINFO: (state, obj) => {
    state.baseInfo = obj
  },
  // 全局保存platform
  REMBER_PLATFORM: (state, str) => {
    state.platform = str
  },
  // 全局保存lang
  REMBER_LANG: (state, str) => {
    state.lang = str
  },
  // 初始化全局sign
  INITIALIZE_GLOBALSIGN: (state) => {
    state.sign = ''
  },
  // 初始化全局lang
  INITIALIZE_GLOBALLANG: (state) => {
    state.sign = 'cn'
  },
  // 初始化全局platform
  INITIALIZE_GLOBALPLATFORM: (state) => {
    state.platform = 'web'
  },
  // 初始化登录消息
  INITIALIZE_BROWSERCHECK: (state) => {
    if (!CSS.supports('--test: demo')) {
      state.passBrowserCheck = false
    } else {
      state.passBrowserCheck = true

    }
  },
}

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    let cookie = req.headers.cookie
    commit('setToken', cookie.token)
  },
  // 初始化登录消息
  initializeLogin({ commit }) {
    commit('INITIALIZE_LOGIN')
  },
  // 初始化全局sign
  initializeGlobalSign({ commit }) {
    commit('INITIALIZE_GLOBALSIGN')
  },
  // 初始化全局platform
  initializeGlobalPlatform({ commit }) {
    commit('INITIALIZE_GLOBALPLATFORM')
  },
  // 初始化全局lang
  initializeGlobalLang({ commit }) {
    commit('INITIALIZE_GLOBALLANG')
  },
  // 全局保存sign
  setSign({ commit }, str) {
    commit('REMBER_SIGN', str)
  },
  // 全局保存是否登录
  setLogin({ commit }, bol) {
    commit('REMBER_LOGIN', bol)
  },
  // 全局保存登录后用户的基本信息
  setBaseInfo({ commit }, obj) {
    commit('REMBER_BASEINFO', obj)
  },
  // 全局保存platform
  setPlatform({ commit }, str) {
    commit('REMBER_PLATFORM', str)
  },
  // 全局保存lang
  setLang({ commit }, str) {
    commit('REMBER_LANG', str)
  },
}
