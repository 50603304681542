import Vue from 'vue'
import {
  Carousel,
  CarouselItem,
  Popover,
  Checkbox,
  CheckboxGroup,
  Pagination,
  Select,
  Option,
  Dialog,
  Radio,
  Message,
  MessageBox,
  Loading,
  Progress,
  Upload,
  DatePicker,
  RadioGroup,
  Form,
  FormItem
} from 'element-ui'

Message.install = function (Vue) {
  Vue.prototype.$message = Message
  Vue.prototype.$confirm = MessageBox.confirm
}

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(Message)
Vue.use(Loading)
Vue.use(Progress)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(RadioGroup)
Vue.use(Form)
Vue.use(FormItem)
