import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _09267e05 = () => interopDefault(import('..\\pages\\accountInfo.vue' /* webpackChunkName: "pages/accountInfo" */))
const _678244ba = () => interopDefault(import('..\\pages\\accountInfo\\collection.vue' /* webpackChunkName: "pages/accountInfo/collection" */))
const _56610e6f = () => interopDefault(import('..\\pages\\accountInfo\\data.vue' /* webpackChunkName: "pages/accountInfo/data" */))
const _eb59a384 = () => interopDefault(import('..\\pages\\accountInfo\\overview.vue' /* webpackChunkName: "pages/accountInfo/overview" */))
const _60fc4856 = () => interopDefault(import('..\\pages\\accountInfo\\record.vue' /* webpackChunkName: "pages/accountInfo/record" */))
const _3c0e098c = () => interopDefault(import('..\\pages\\aimbot\\index.vue' /* webpackChunkName: "pages/aimbot/index" */))
const _02985412 = () => interopDefault(import('..\\pages\\b5plusprotocol\\index.vue' /* webpackChunkName: "pages/b5plusprotocol/index" */))
const _4a1c30fe = () => interopDefault(import('..\\pages\\banned\\index.vue' /* webpackChunkName: "pages/banned/index" */))
const _151b35d0 = () => interopDefault(import('..\\pages\\contact\\index.vue' /* webpackChunkName: "pages/contact/index" */))
const _ffd8e658 = () => interopDefault(import('..\\pages\\download\\index.vue' /* webpackChunkName: "pages/download/index" */))
const _694adf8e = () => interopDefault(import('..\\pages\\Exchange\\index.vue' /* webpackChunkName: "pages/Exchange/index" */))
const _1f0b291c = () => interopDefault(import('..\\pages\\gameTool\\index.vue' /* webpackChunkName: "pages/gameTool/index" */))
const _04ba8599 = () => interopDefault(import('..\\pages\\Management\\index.vue' /* webpackChunkName: "pages/Management/index" */))
const _0e902ccc = () => interopDefault(import('..\\pages\\myTournaments\\index.vue' /* webpackChunkName: "pages/myTournaments/index" */))
const _0e1ef2ae = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _14734678 = () => interopDefault(import('..\\pages\\protocol\\index.vue' /* webpackChunkName: "pages/protocol/index" */))
const _38bea3b0 = () => interopDefault(import('..\\pages\\rank\\index.vue' /* webpackChunkName: "pages/rank/index" */))
const _1402adb4 = () => interopDefault(import('..\\pages\\tournaments.vue' /* webpackChunkName: "pages/tournaments" */))
const _1ed95236 = () => interopDefault(import('..\\pages\\tournaments\\_id.vue' /* webpackChunkName: "pages/tournaments/_id" */))
const _b0938c40 = () => interopDefault(import('..\\pages\\tournaments\\_id\\data.vue' /* webpackChunkName: "pages/tournaments/_id/data" */))
const _7c86e030 = () => interopDefault(import('..\\pages\\tournaments\\_id\\final.vue' /* webpackChunkName: "pages/tournaments/_id/final" */))
const _e5598da2 = () => interopDefault(import('..\\pages\\tournaments\\_id\\overview.vue' /* webpackChunkName: "pages/tournaments/_id/overview" */))
const _56524490 = () => interopDefault(import('..\\pages\\tournaments\\_id\\stage.vue' /* webpackChunkName: "pages/tournaments/_id/stage" */))
const _4cc209da = () => interopDefault(import('..\\pages\\tournaments\\_id\\team.vue' /* webpackChunkName: "pages/tournaments/_id/team" */))
const _a8515e7a = () => interopDefault(import('..\\pages\\verify\\index.vue' /* webpackChunkName: "pages/verify/index" */))
const _2b49d468 = () => interopDefault(import('..\\pages\\Help\\Help.vue' /* webpackChunkName: "pages/Help/Help" */))
const _1940f8b6 = () => interopDefault(import('..\\pages\\protocol\\Protocol.vue' /* webpackChunkName: "pages/protocol/Protocol" */))
const _eb9ebb4c = () => interopDefault(import('..\\pages\\gameTool\\_id.vue' /* webpackChunkName: "pages/gameTool/_id" */))
const _66f0eafc = () => interopDefault(import('..\\pages\\myTournaments\\_id.vue' /* webpackChunkName: "pages/myTournaments/_id" */))
const _75dbc15e = () => interopDefault(import('..\\pages\\news\\_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _4da8dc5c = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _aa28232e = () => interopDefault(import('..\\pages\\_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accountInfo",
    component: _09267e05,
    name: "accountInfo",
    children: [{
      path: "collection",
      component: _678244ba,
      name: "accountInfo-collection"
    }, {
      path: "data",
      component: _56610e6f,
      name: "accountInfo-data"
    }, {
      path: "overview",
      component: _eb59a384,
      name: "accountInfo-overview"
    }, {
      path: "record",
      component: _60fc4856,
      name: "accountInfo-record"
    }]
  }, {
    path: "/aimbot",
    component: _3c0e098c,
    name: "aimbot"
  }, {
    path: "/b5plusprotocol",
    component: _02985412,
    name: "b5plusprotocol"
  }, {
    path: "/banned",
    component: _4a1c30fe,
    name: "banned"
  }, {
    path: "/contact",
    component: _151b35d0,
    name: "contact"
  }, {
    path: "/download",
    component: _ffd8e658,
    name: "download"
  }, {
    path: "/Exchange",
    component: _694adf8e,
    name: "Exchange"
  }, {
    path: "/gameTool",
    component: _1f0b291c,
    name: "gameTool"
  }, {
    path: "/Management",
    component: _04ba8599,
    name: "Management"
  }, {
    path: "/myTournaments",
    component: _0e902ccc,
    name: "myTournaments"
  }, {
    path: "/news",
    component: _0e1ef2ae,
    name: "news"
  }, {
    path: "/protocol",
    component: _14734678,
    name: "protocol"
  }, {
    path: "/rank",
    component: _38bea3b0,
    name: "rank"
  }, {
    path: "/tournaments",
    component: _1402adb4,
    name: "tournaments",
    children: [{
      path: ":id?",
      component: _1ed95236,
      name: "tournaments-id",
      children: [{
        path: "data",
        component: _b0938c40,
        name: "tournaments-id-data"
      }, {
        path: "final",
        component: _7c86e030,
        name: "tournaments-id-final"
      }, {
        path: "overview",
        component: _e5598da2,
        name: "tournaments-id-overview"
      }, {
        path: "stage",
        component: _56524490,
        name: "tournaments-id-stage"
      }, {
        path: "team",
        component: _4cc209da,
        name: "tournaments-id-team"
      }]
    }]
  }, {
    path: "/verify",
    component: _a8515e7a,
    name: "verify"
  }, {
    path: "/Help/Help",
    component: _2b49d468,
    name: "Help-Help"
  }, {
    path: "/protocol/Protocol",
    component: _1940f8b6,
    name: "protocol-Protocol"
  }, {
    path: "/gameTool/:id",
    component: _eb9ebb4c,
    name: "gameTool-id"
  }, {
    path: "/myTournaments/:id",
    component: _66f0eafc,
    name: "myTournaments-id"
  }, {
    path: "/news/:id",
    component: _75dbc15e,
    name: "news-id"
  }, {
    path: "/",
    component: _4da8dc5c,
    name: "index"
  }, {
    path: "/*",
    component: _aa28232e,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
