import { Message } from 'element-ui'

export default ({ app }, inject) => {
  const baseURL = process.env.baseUrl
  const http = app.$axios.create({
    timeout: 50000,
    credentials: false,
  })
  http.setBaseURL(baseURL)

  http.onRequest((config) => {
    // console.log('making request to' + config.url)
    return config
  })

  http.onRequestError((err) => {
    return Promise.reject(new Error(err || 'Error'))
  })

  http.onResponse((response) => {
    if (response.status === 200) {
      const data = response.data
      if (data.code === 0 || 403) {
        return data
      } else {
        if (process.client) {
          Message({
            message: data.message,
            type: 'error',
          })
        }
      }
    } else {
      if (process.client) {
        Message({
          message: '网络错误',
          type: 'error',
        })
      }
      return Promise.reject(new Error(response.data.message || 'Error'))
    }
  })

  http.onResponseError((err) => {
    console.log({ err })
    if (process.client) {
      Message({
        message: '网络错误',
        type: 'error',
      })
    }
    return Promise.reject(new Error(err || 'Error'))
  })

  inject('http', http)
}
