export default (ctx) => {
  // 在页面刷新时将vuex里的信息保存到sessionStorage里
  window.addEventListener('beforeunload', () => {
    sessionStorage.setItem('b5web_store', JSON.stringify(ctx.store.state))
  })
  // 在页面加载时读取sessionStorage里的状态信息
  if (sessionStorage.getItem('b5web_store')) {
    ctx.store.replaceState(
      Object.assign(
        {},
        ctx.store.state,
        JSON.parse(sessionStorage.getItem('b5web_store'))
      )
    )
  }
}
