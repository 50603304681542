export default {
  b5: 'B5对战平台 v5.0 电竞本该纯粹',
  sss: '下载安装',
  title: '最低运行配置',
  notice:
    '由于BBI反作弊系统会占用一部分系统资源，所以B5将对最低运行配置提出要求',
  table: '台式机配置',
  system: '系统 ',
  systemcontent: ' win7/win10 64位 (win10不支持小于1803版本及预览版）',
  cpu: 'CPU ',
  cpucontent: ' Intel i5-4590',
  gpu: '显卡 ',
  gpucontent: ' GTX 750TI',
  nc: '内存 ',
  nccontent: ' 8G及以上内存',
  book: '笔记本配置',
  cpuofbook: ' i7-4750HQ',
  gpuofbook: ' GTX 965M',
}
