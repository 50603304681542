module.exports = {
  // 首页
  index: {
    nav: {
      rank: '排行榜',
      forbid: '封禁处理',
      common: '常见问题',
    },
    main: {
      section1: {
        title: '简单三步，加入B5对战平台',
        step1: '1，下载安装B5客户端',
        step2: '2，客户端点击注册B5账号',
        step3: '3，验证CSGO账号天梯匹配资格',
      },
      section2: {
        title: 'BBI反作弊系统',
        content:
          '任何运行、销售、宣传、存储CSGO作弊器用户皆会被封禁BBI反作弊对于外挂使用者从来都是0容忍',
      },
      section3: {
        title: '真128Tick高配置服务器',
        sb: '配置高',
        sbcontent1: 'var<0.01、sv<4.0',
        sbcontent2: '真128tick服务器，确保枪法时刻在线',
        sx: '覆盖广',
        sxcontent1: '杭州/北京/广州/成都/西安',
        sxcontent2: '*拒绝做高Ping战士',
        sxcontent3: '*其他地区服务器陆续搭建中',
      },
      section4: {
        title: 'B5训练助手',
        content:
          'CFG云同步，省去繁琐调机器时间练习地图高速下载，无视创意工坊访问异常',
      },
      section5: {
        title: '挑战更高段位',
        sb: '参与10场定级赛获取段位',
        duan1: '青铜',
        duan1point: 'elo < 900',
        duan2: '白银',
        duan2point: 'elo 900',
        duan3: '黄金',
        duan3point: 'elo 1300',
        duan4: '精英',
        duan4point: 'elo 1700',
        duan5: '无上大师',
        duan5point: 'elo >= 2000',
      },
      section6: {
        naocaotitle: '小黑盒APP查询B5战绩',
        naocao:
          '为了B5用户能够更好的查询战绩数据，B5联手小黑盒APP打造战绩系统，用户可直接下载小黑盒手机APP查询战绩',
      },
    },
    footer: {
      weibo: '官方微博',
      contact: '联系我们',
      job: '招聘精英',
      protocol: '用户协议',
      rightone: '苏公网安备 32050502000557号        沪ICP备16032035号',
      righttwo: 'Copyright 2016-2019 www.b5csgo.com.cn All Rights Reserved',
    },
    step: '下载B5',
    download: '下载小黑盒APP',
  },
}
