export const state = () => ({
  baseinfo: {},
})

export const mutations = {
  // 初始化个人中心用户信息
  INITIALIZE_ACCOUNTINFO: (state) => {
    state.baseinfo = {}
  },
  // 保存个人中心用户信息
  SAVE_BASEINFO: (state, obj) => {
    state.baseinfo = obj
  },
  // 修改个人中心头部勋章
  SAVE_MEDALINFO: (state, arr) => {
    state.baseinfo.medalinfo = arr
  },
  // 修改个人中心背景板
  SAVE_BGINFO: (state, str) => {
    state.baseinfo.bginfo = str
  },
}

export const actions = {
  // 初始化个人中心用户信息
  initializeAccountInfo({ commit }) {
    commit('INITIALIZE_ACCOUNTINFO')
  },
  // 保存个人中心用户基本信息
  saveBaseInfo({ commit }, obj) {
    commit('SAVE_BASEINFO', obj)
  },
  // 修改个人中心头部勋章
  saveMedalinfo({ commit }, arr) {
    commit('SAVE_MEDALINFO', arr)
  },

  // 修改个人中心背景板
  saveBginfo({ commit }, str) {
    commit('SAVE_BGINFO', str)
  },
}
