import axios from 'axios'
const http = axios.create({
  withCredentials: true,
  baseURL: process.env.baseUrl,
})

export default {
  /**
   * 账号密码登录
   * params userName
   * params password
   **/
  async userLogin(options) {
    const { data } = await http.get(
      `/auth/login?userName=${options.emailOrUsername}&password=${options.encrypt}&flag=${options.flag}&sessionId=${options.SessionId}&sig=${options.Sig}&token=${options.Token}&scene=${options.Scene}`
    );
    return data;
  },

  /**
   * 用户verify
   * 调用的时候会携带cookie
   * 所以可以在页面初始化时调用一次
   * 在cookie有效期内实现自动登录
   **/
  async checkIn() {
    const { data } = await http.get(`/auth/verify`)
    return data
  },

  /**
   * 退出登录
   **/
  async webLogOut() {
    const { data } = await http.post(`/auth/logout`)
    return data
  },

  /**
   * 人机验证验签
   * params SessionId
   * params Sig
   * params Token
   * params Scene
   **/
  async getAuthenticateSig(SessionId, Sig, Token, Scene) {
    const { data } = await http.get(
      `/csgo/getAuthenticateSig?sessionId=${SessionId}&sig=${Sig}&token=${Token}&scene=${Scene}`
    )
    return data
  },

  /**
   * 自动登录Web
   * params steamId
   * params token
   **/
  async autoInit(steamId, token) {
    let { data } = await http({
      headers: { 'Content-Type': 'application/json' },
      transformRequest: [
        function (data) {
          data = JSON.stringify(data)
          return data
        },
      ],
      url: `/auth/session/autoInit`,
      method: 'post',
      data: {
        steamId,
        token,
      },
    })
    return data
  },
}
